import Http from "@/services/Http";

export default {
  getAllSubscribedProgramExams(data) {
    return Http().post("exams/list", data);
  },
  getSubscribedProgramExamDetails(data) {
    return Http().post("exam/details", data);
  },
  getSubscribedProgramExamQuestions(data) {
    return Http().post("exam/questions", data);
  },
  submitSubscribedProgramExamResult(data) {
    return Http().post("exam/submit", data);
  },
  getTodayExams() {
    return Http().post("exams/today");
  },
  getExamInstructions(data) {
    return Http().post("exam/instructions", data);
  },
};
