<template>
  <div class="blog-listing mt-0">
    <div class="head-title">
      <div class="container">
        <h5 class="color-1">Exam Instructions</h5>
      </div>
    </div>
    <div v-if="instructionsLoading" class="loading">Loading...</div>
    <div v-else class="container">
        <div class="exam-instruction"
          v-if="examInstructions != null && examInstructions.exam_instructions != null"
          v-html="examInstructions.exam_instructions"
        >
        </div>
        <div v-else>No Instructions
        </div>
      <div class="btn-groups">
        <router-link
          v-if="examInstructions != null"
          :to="{
            name: 'CourseExam',
            params: {
              program_id: examInstructions.program_id,
              exam_id: examInstructions.exam_id,
            },
          }"
          class="btn small-cta"
          >Start</router-link
        >
        <router-link
          v-if="examInstructions != null"
          :to="{
            name: 'Dashboard',
          }"
          class="btn small-cta"
          >Cancel
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ExamService from "@/services/ExamService";
export default {
  name: "CourseExamInstructions",
  data() {
    return {
      instructionsLoading: true,
      examInstructions: null,
    };
  },
  created() {
    this.loadInstructions();
  },
  methods: {
    async loadInstructions() {
      let params = {
        exam_id: this.$route.params.exam_id,
      };
      await ExamService.getExamInstructions(params)
        .then((output) => {
          this.instructionsLoading = false;
          if (output.data.status == "SUCCESS") {
            this.examInstructions = output.data.instructions;
          } else if (output.data.status == "ERROR") {
            this.$toast.error(output.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/content-page.scss";
@import "@/style/listing.scss";
</style>
